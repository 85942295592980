import { ResourceKey } from 'i18next';
import {
  Clause,
  Condition,
  ContentBlock,
  Expression,
  Option,
  Organization,
  Question,
  QuestionGroup,
  Questionnaire,
  QuestionnaireMetadata,
  RequestingAuthoritiesQuery,
  Response,
  ResponseAttachment,
  ResponseStatus,
  ResponsesQuery,
  Section,
} from '@/lib/response/__generated__/graphql';
// utils
import { currencies } from '@/utils/currencies';

// types
export * from '@/lib/response/__generated__/graphql';

export type NumberOrString = number | string;

export type HomeProgressPoint = {
  whenToShow: QuestionnaireProgressStatuses[];
  keyLabel: string;
  value: string | number;
  icon: React.ReactNode;
  color: string;
  linkLabel?: string;
};

export type ProgressPoints = {
  answered: number;
  reviewed: number;
  started: number;
  skipped: number;
  total: number;
  conditional: number;
};

export type Progress = {
  // all questions status
  totalQuestions: number;
  //  ANSWERED
  answeredQuestions: Set<NumberOrString>;
  //  REVIEWED
  reviewedQuestions: Set<NumberOrString>;
  // IN PROGRESS ( mainly used by complex questions)
  startedQuestions: Set<NumberOrString>;
  // SKIPPED
  skippedQuestions: Set<NumberOrString>;
  // CONDITIONAL
  conditionalQuestions: Set<NumberOrString>;
};

export enum ProgressStatus {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Progress = 'PROGRESS',
}

export const ExtensionTimeWeeks: { [key: string]: number } = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  eleven: 11,
  twelve: 12,
  thirteen: 13,
  fourteen: 14,
  fifteen: 15,
  sixteen: 16,
  seventeen: 17,
  eighteen: 18,
  nineteen: 19,
  twenty: 20,
};

export const ExtensionReasons: { [key: string]: string } = {
  ods: 'ods',
  exception: 'exception',
};

export enum QuestionnaireProgressStatuses {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  SCORED = 'SCORED',
}

export enum BlankOptions {
  IGNORE = 1,
  TREAT_AS_ZERO = 2,
}

export enum HelpCenterCategories {
  QUESTIONNAIRE_RESPONSE = 'questionnaireResponse',
  ACCOUNTS_AND_ACCESS = 'accountsAndAccess',
  ADD_CITY = 'addCity',
}

export enum HelpCenterSubCategories {
  EXTENSION_REQUEST = 'extensionRequest',
  DISCLOSURE_PLATFORM_GUIDANCE = 'disclosurePlatformGuidance',
  REPORT_PLATFORM_ISSUE = 'reportPlatformIssue',
  MAKE_RESPONSE_PUBLIC = 'makeMyResponsePublic',
  ISSUES_SUBMITTING_QUESTIONNAIRE = 'issuesSubmittingQuestionnaire',
  ADD_CITY = 'addCity',
  AUTH_REQUEST_FOR_DUNS_INFORMATION = 'authRequestForDunsInformation',
}

export enum SectionType {
  INFORMATION_BLOCK = 'INFORMATION_BLOCK',
  QUESTION_SECTION = 'QUESTION_SECTION',
}

// if no other character limits defined, then using this constant to limit characters in editor
export const FREETEXT_CHARACTER_LIMIT = 5000;

export type ToDoListItemType = {
  title: string | ResourceKey;
  link?: string;
  newTab?: boolean;
};

export type ComplexQuestionProgress = {
  rows: Record<NumberOrString, Progress>;
};

export type QuestionnaireProgress = {
  sections: Record<NumberOrString, Progress>;
  complexQuestions: Record<NumberOrString, ComplexQuestionProgress>;
  // map questions ids to sectionId
  sectionMap: Record<NumberOrString, NumberOrString>;
};

export type ResponseValue = Response['content'];

export type QuestionGroupedOption = {
  group: string;
  options: Option[];
};

export type Attachment = ResponseAttachment & {
  loading?: boolean;
};

// File uploader
export interface ExtendFile extends File {
  attachmentId?: number;
  fileSize?: number;
  hash?: string;
  loading?: boolean;
}

export type Ids = {
  leadingQuestionId?: NumberOrString;
  rowId?: NumberOrString;
  questionId: NumberOrString;
  optionId?: NumberOrString;
};

export type LocalResponse = {
  content: ResponseValue;
  attachments?: ResponseAttachment[] | undefined | null;
  status: ResponseStatus;
  errorMessages?: Array<string>;
} & Ids;

export type BasicResponse = {
  type: string | 'BASIC';
} & LocalResponse;

export type QuestionResponse = LocalResponse;

export type ColumnGroup = {
  questions: Question[];
} & QuestionGroup;

export enum ClauseKind {
  ChildQuestion, // parentQuestionId - rowId - questionId
  Column, // parentQuestionId - questionId
  Row, // parentQuestionId - rowId
  Question, // questionId
  Option, // optionId
}

export type QuestionClause = {
  conditions: Condition[];
  display: boolean;
  kind: ClauseKind;
} & Ids;

export enum ExportFormat {
  WORD = 'word',
  PDF = 'pdf',
}

export enum ExportOption {
  ALL = 'all',
  BY_SECTION = 'section',
  BY_TYPE = 'type',
}

export type ExportQuestionnaire = {
  // 0 -> pick format
  // 1 -> export questionnaire
  step: number;
  format?: ExportFormat;
  option?: ExportOption;
  sections: Set<NumberOrString>;
  // TRUE means that the questionnaire is being exported
  exporting: boolean;
};

/**
 * Guidance
 */

export type Block = {
  order: number;
  text: string;
  name: string;
};

/**
 * Submission
 */

export enum SubmissionStep {
  QUESTIONNAIRE,
  REQUESTS,
  SUBMISSION_TYPE,
  SUBMIT,
  DONE,
}

export enum SubmissionStatus {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  FAILED,
}

export enum ResponseStatusEnum {
  NO_RESPONSE = 'No Response',
  ACTIVATED = 'Activated',
  SUBMITTED = 'Submitted',
  AMENDED = 'Amended',
  REQUEST_NOT_SENT = 'Request Not Sent',
}

export type Submission = {
  step: SubmissionStep;
  // Responses that have been submitted publicly will be published on the CDP website
  isPublic: boolean;
  submissionDate?: Date;
};

export type UncompletedSubmission = {
  section: Section;
  progress: ProgressPoints;
  mandatory: number;
};

export type OptionResponse =
  | {
      type: 'option';
      value: Option | null;
      status: ResponseStatus;
    }
  | {
      type: 'options';
      value: Option[];
      status: ResponseStatus;
    }
  | {
      type: 'groupedOptions';
      value: QuestionGroupedOption[];
      status: ResponseStatus;
    }
  | {
      type: 'unknown';
      value: undefined;
      status: ResponseStatus;
    };

// Cross validation
export type CrossValidation = {
  operand: string;
  crossValidation: NumberOrString[];
  margin?: number | null;
  errorMessage?: string | null;
};

export type CrossValidations = {
  crossValidations: Array<{
    questionId: string;
    leadingQuestionId?: string | null;
    rowId?: string | null;
    postfix: string;
    operand: string;
    margin?: number | null;
    errorMessage?: string | null;
  }>;
};

export type ContentBlockWithMetaData = { title: string; id: string; type: string; block: ContentBlock };

export type SaveStatus = 'idle' | 'saving' | 'retrying' | 'saved' | 'error';

/**
 * Response store
 */

export interface ResponseStore {
  metadata?: QuestionnaireMetadata;
  requestingAuthorities: Organization[];
  questionnaire?: Questionnaire;
  dynamicRowMapping: Map<NumberOrString, { name: string; id: string }[]>;
  staticRowMapping: Map<NumberOrString, { name: string; id: string }[]>;
  questionsNumbers: Map<NumberOrString, NumberOrString>;
  responses: Record<NumberOrString, QuestionResponse>;
  currentSectionIdx: number;
  currentQuestionIdx: number;
  currentGuidanceQuestionIdx: number;
  progress: QuestionnaireProgress;
  defaultCurrency: (typeof currencies)[number];
  saving: SaveStatus;
  lastSaved: Date | undefined;
  dirtyFields: Record<string, boolean>;
  errorFields: Record<string, boolean>;
  // display/hide questions based on 'clauses' evaluation
  clauses: Map<NumberOrString, QuestionClause>;
  clausesPreview: Clause[];
  clausesFetched: Clause[];
  disableCL: boolean;
  isCalculatingCL: boolean;
  availableTemplates: Set<string>;
  expressionsPreview: Expression[];
  expressionsFetched: Expression[];
  exportQuestionnaire: ExportQuestionnaire;
  // auto calculated fields ( based on postfix expressions )
  expressions: Map<NumberOrString, NumberOrString[]>;
  // submission
  submission: Submission;
  responsesInitialized: boolean;
  isToInternetConnected: boolean;

  // stores questions IDs to filter out irrelevant responses / clauses / expressions
  // ... the ons associated with questions no longer present in the questionnaire (e.g., due to tag changes, etc.)
  idSet: Set<NumberOrString>;
  // scenario testing for auhtors
  skipSave: boolean;
  // guidance view
  blocksByQuestion: Map<NumberOrString, ContentBlock[]>;
  isGuidance: boolean;
  // cross validation
  crossValidation: Map<NumberOrString, CrossValidation>;
  importGridErrors: Map<NumberOrString, string[]>;

  lastChangesHash: string;

  actions: {
    setMetadata: (metadata: QuestionnaireMetadata | undefined) => void;
    setRequestingAuthorities: (data: RequestingAuthoritiesQuery) => void;
    setQuestionnaire: (data: Questionnaire) => void;
    setQuestionsNumbers: (questionsNumbers: Map<NumberOrString, NumberOrString>) => void;
    setResponse: (value: LocalResponse) => void;
    setResponseBatch: (responses: LocalResponse[]) => void;
    setResponses: (data: ResponsesQuery['responses']) => void;
    setCrossValidation: (data: CrossValidations) => void;
    setCurrentSection: (idx: number) => void;
    setCurrentQuestion: (idx: number) => void;
    setCurrentGuidanceQuestion: (idx: number) => void;
    setSaving: (saving: SaveStatus) => void;
    setLastSaved: (date: Date | undefined) => void;
    initStore: (questionnaire: Questionnaire) => void;
    resetDirtyFields: () => void;
    setAvailableTemplates: (questionIds: string[]) => void;
    //cond logic
    setClauses: (clauses: Clause[]) => void;
    setClausesFetched: (clauses: Clause[]) => void;
    setClausesPreview: (clauses: Clause[]) => void;
    setImportGridErrors: (questionId: NumberOrString, errors: string[]) => void;
    setClause: (entry: { key: NumberOrString; value: QuestionClause }) => void;
    setClauseBatch: (entries: { key: NumberOrString; value: QuestionClause }[]) => void;
    setDisableCL(display: boolean): void;
    setIsCalculatingCL: (isCalculatingCL: boolean) => void;
    setExportQuestionnaire: (exportQuestionnaire: Partial<ExportQuestionnaire>) => void;
    resetExportQuestionnaire: () => void;
    // auto calc
    setExpressions: (exprssions: Expression[]) => void;
    setExpressionsFetched: (expressions: Expression[]) => void;
    setExpressionsPreview: (expressions: Expression[]) => void;
    // submission
    nextSubmissionStep: (isAlreadyPublic: boolean) => void;
    prevSubmissionStep: (isAlreadyPublic: boolean) => void;
    shareSubmission: () => void;
    setIsToInternetConnected: (isToInternetConnected: boolean) => void;

    cleanAfterRowDeletion: (leadinQuestionId: NumberOrString, rowIds: NumberOrString[]) => void;
    updateAfterRowAddition: (leadinQuestionId: NumberOrString) => void;
    // preview mode
    setSkipSave: (skipSave: boolean) => void;
    setResponsesInitialized: () => void;
    // guidance view
    setGuidance: (isGuidance: boolean) => void;
    setBlockByQuestion: (blocks: Map<NumberOrString, ContentBlock[]>) => void;

    setLastChangesHash: (lastChangesHash: string) => void;
  };
}

export type PartnerModel = {
  id: string;
  name?: string;
  link?: string;
  countryName?: string;
  countryAlpha2?: string;
};
